import React, {
  useMemo,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useState,
  useEffect,
} from 'react'
import ReactTooltip from 'react-tooltip'

import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { isCnpj } from 'validator-brazil'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import Radio from '@material-ui/core/Radio'
import TextField from '@material-ui/core/TextField'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import makeStyles from '@material-ui/core/styles/makeStyles'

import { GlobalMessages } from '../../../shared/messages'
import { useEndorsement } from '../../../shared/contexts/EndorsementContext'

import {
  useLoader,
  DateInput,
  ButtonLight,
  ButtonGradient,
  CPFOrCNPJInput,
} from '../../../shared/components'
import CollapsePrice from '../../../shared/components/CollapsePrice/CollapsePrice'
import CurrencyInput from '../../../shared/components/CurrencyInput/CurrencyInput'

import EndorsementNewStateDocument from '../../../models/PolicyModels/EndorsementNewStateDocument'
import useUtils from '../../../shared/hooks/useUtils'
import useUserClient from '../../../clients/UserClient/useUserClient'
import useReceitaWSClient from '../../../clients/ReceitaWSClient/useReceitaWSClient'
import {
  QUOTE_PERFORMANCE,
  QUOTE_JUDICIAL_APPEAL,
  PRODUCT_KEY_JUDICIAL_APPEAL,
  PRODUCT_KEY_JUDICIAL_CIVEL,
  PRODUCT_KEY_JUDICIAL_LABOR,
  PRODUCT_KEY_JUDICIAL_CIVEL_MIN,
} from '../../../constants'
import {
  ENDORSEMENT_TYPE_APPEAL_WRITE_OFF,
  ENDORSEMENT_TYPE_DEADLINE_EXTENSION,
  ENDORSEMENT_TYPE_INCLUSION_COVERAGE,
  ENDORSEMENT_TYPE_INCREASE_INSURED_AMOUNT,
  ENDORSEMENT_TYPE_REDUCTION_INSURED_AMOUNT,
  ENDORSEMENT_TYPE_DATA_OR_OBJECT_CORRECTION,
  ENDORSEMENT_TYPE_EXTENSION_INCREASE_INSURED_AMOUNT,
  ENDORSEMENT_TYPE_EXTENSION_REDUCTION_INSURED_AMOUNT,
} from '../../../constants/EndorsementConstants'
import DropdownSelectOne from '../../../shared/components/DropdownSelectOne/DropdownSelectOne'
import useYup from '../../../shared/hooks/useYup'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
  labelObject: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
  },
  labelMessage: {
    color: 'red',
    fontSize: 14,
    margin: 2,
  },
}))

const MODALITY_PUBLIC = 2
const MODALITY_PRIVATE = 3
const INSURED_AMOUNT = 'Importância Segurada'

const NewStateDocument = forwardRef((props, ref) => {
  const classes = useStyles()
  const {
    checked,
    handleStep,
    handleNext,
    handlePrevious,
  } = props

  const {
    onNext,
    actionNext,
    endorsement,
    setEndorsement,
    endorsementData,
    otherBrokerageDocument,
  } = useEndorsement()

  const {
    date,
    isBlank,
    ternaryCheck,
    formatDate,
    formatDateApi,
    formatCurrencyBRLToDecimal,
  } = useUtils()

  const receitaWSClient = useReceitaWSClient()

  const { showLoader, hideLoader } = useLoader()
  const { cpfOrCnpj } = useYup()

  const userClient = useUserClient()

  const [endDate, setEndDate] = useState('')
  const [deadline, setDeadline] = useState('')
  const [DEADLINE_YEARS] = useState(ternaryCheck((
    endorsementData?.productKey === PRODUCT_KEY_JUDICIAL_CIVEL
    || endorsementData?.productKey === PRODUCT_KEY_JUDICIAL_CIVEL_MIN
  ), [2, 3, 4, 5], [3, 4, 5]))
  const [contractValue, setContractValue] = useState(0)
  const [guaranteeValue, setGuaranteeValue] = useState(0)

  const isShowContractAndGuaranteeLabel = useCallback(() => (![
    ENDORSEMENT_TYPE_EXTENSION_INCREASE_INSURED_AMOUNT,
    ENDORSEMENT_TYPE_INCREASE_INSURED_AMOUNT,
    ENDORSEMENT_TYPE_EXTENSION_REDUCTION_INSURED_AMOUNT,
    ENDORSEMENT_TYPE_REDUCTION_INSURED_AMOUNT,
  ].includes(endorsementData?.id)), [endorsementData?.id])

  const isTypeExtension = useCallback(() => ([
    ENDORSEMENT_TYPE_DEADLINE_EXTENSION,
    ENDORSEMENT_TYPE_EXTENSION_INCREASE_INSURED_AMOUNT,
    ENDORSEMENT_TYPE_EXTENSION_REDUCTION_INSURED_AMOUNT,
  ].includes(endorsementData?.id)), [endorsementData?.id])

  const isTypeNoInsuredAmount = useCallback(() => ([
    ENDORSEMENT_TYPE_DEADLINE_EXTENSION,
    ENDORSEMENT_TYPE_DATA_OR_OBJECT_CORRECTION,
    ENDORSEMENT_TYPE_APPEAL_WRITE_OFF,
    ENDORSEMENT_TYPE_INCLUSION_COVERAGE,
  ].includes(endorsementData?.id)), [endorsementData?.id])

  const policyOrLastPolicy = formatDateApi(
    endorsement?.currentStateDocument?.startDateValidity,
  ) ?? undefined
  const policyOrLastPolicyEndDate = formatDateApi(
    endorsement?.currentStateDocument?.endDateValidity,
  ) ?? undefined
  const policyOrLastPolicyIsTypeExtension = date(policyOrLastPolicyEndDate).add(1, 'days')?.format('YYYY-MM-DD') ?? undefined
  const verifyPolicyIsTypeExtension = useCallback(() => {
    if (isTypeExtension()) {
      return policyOrLastPolicyIsTypeExtension
    }
    if (endorsementData?.id === 5) {
      return ''
    }
    return policyOrLastPolicy
  }, [isTypeExtension, policyOrLastPolicyIsTypeExtension, policyOrLastPolicy, endorsementData])

  const initialValuesForm = useMemo(() => (Object.assign(
    EndorsementNewStateDocument({
      name: endorsement?.currentStateDocument?.name ?? undefined,
      document: endorsement?.currentStateDocument?.document ?? undefined,
      deadline: !isTypeExtension() ? endorsement?.currentStateDocument?.deadline : undefined,
      startDateValidity: !otherBrokerageDocument ? verifyPolicyIsTypeExtension() : '',
      endDateValidity: !isTypeExtension() ? formatDateApi(
        endorsement?.currentStateDocument?.endDateValidity,
      ) : undefined,
      contractValue: endorsement?.currentStateDocument?.contractValue ?? 0,
      guaranteeValue: isShowContractAndGuaranteeLabel()
        ? endorsement?.currentStateDocument?.guaranteeValue
        : 0,
      objectText: endorsement?.currentStateDocument?.objectText?.replace('', '')?.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '') ?? undefined,
    }),
  )), [
    formatDateApi,
    isTypeExtension,
    otherBrokerageDocument,
    verifyPolicyIsTypeExtension,
    isShowContractAndGuaranteeLabel,
    endorsement?.currentStateDocument,
  ])

  const initialValuesFormEditing = useMemo(() => (Object.assign(
    EndorsementNewStateDocument({
      name: endorsement?.newStateDocument?.name ?? undefined,
      document: endorsement?.newStateDocument?.document ?? undefined,
      deadline: endorsement?.newStateDocument?.deadline,
      startDateValidity: formatDateApi(endorsement?.newStateDocument?.startDateValidity),
      endDateValidity: formatDateApi(endorsement?.newStateDocument?.endDateValidity),
      contractValue: endorsement?.newStateDocument?.contractValue ?? undefined,
      guaranteeValue: endorsement?.newStateDocument?.guaranteeValue,
      objectText: endorsement?.newStateDocument?.objectText?.replace('', '')?.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '') ?? undefined,
    }),
  )), [
    formatDateApi,
    endorsement?.newStateDocument,
  ])

  const isShowDeadLineAndEndDateLabel = () => (![
    ENDORSEMENT_TYPE_DEADLINE_EXTENSION,
    ENDORSEMENT_TYPE_EXTENSION_INCREASE_INSURED_AMOUNT,
    ENDORSEMENT_TYPE_EXTENSION_REDUCTION_INSURED_AMOUNT,
  ].includes(endorsementData?.id))

  const isShowStartDateLabel = () => (![
    ENDORSEMENT_TYPE_DEADLINE_EXTENSION,
    ENDORSEMENT_TYPE_INCLUSION_COVERAGE,
    ENDORSEMENT_TYPE_INCREASE_INSURED_AMOUNT,
    ENDORSEMENT_TYPE_REDUCTION_INSURED_AMOUNT,
    ENDORSEMENT_TYPE_DATA_OR_OBJECT_CORRECTION,
    ENDORSEMENT_TYPE_EXTENSION_INCREASE_INSURED_AMOUNT,
    ENDORSEMENT_TYPE_EXTENSION_REDUCTION_INSURED_AMOUNT,
  ].includes(endorsementData?.id))

  const isShowObjectLabel = () => (
    endorsementData?.id !== ENDORSEMENT_TYPE_INCLUSION_COVERAGE
  )
  const verifyJudicials = (
    endorsementData?.productKey === PRODUCT_KEY_JUDICIAL_APPEAL
    || endorsementData?.productKey === PRODUCT_KEY_JUDICIAL_LABOR
    || endorsementData?.productKey === PRODUCT_KEY_JUDICIAL_CIVEL
    || endorsementData?.productKey === PRODUCT_KEY_JUDICIAL_CIVEL_MIN)

  const deadlineLabel = ternaryCheck(verifyJudicials, 'Prazo (Anos)', 'Prazo (Dias)')
  const contractValueLabel = ternaryCheck((
    endorsementData?.productKey === PRODUCT_KEY_JUDICIAL_CIVEL
    || endorsementData?.productKey === PRODUCT_KEY_JUDICIAL_CIVEL_MIN), 'Valor da Garantia:', 'Valor Contrato:')
  const guaranteeValueLabel = ternaryCheck(verifyJudicials, 'Nova Importância Segurada:', 'Valor Total da Garantia (Nova Importância Segurada):')

  const fieldContractValue = () => (Yup.number().when(() => (
    isShowContractAndGuaranteeLabel()
      ? Yup.number()
      : Yup.number()
        .test('contractValue', GlobalMessages.CAMPO_MAIOR_ZERO(contractValueLabel?.split(':').join('')), (value) => (value && Number(value) !== 0))
        .required()
  )))

  const fieldGuaranteeValue = () => {
    if (!otherBrokerageDocument
      && (endorsementData?.id === ENDORSEMENT_TYPE_EXTENSION_INCREASE_INSURED_AMOUNT
        || endorsementData?.id === ENDORSEMENT_TYPE_INCREASE_INSURED_AMOUNT)
    ) {
      return Yup.number()
        .test('guaranteeValue', GlobalMessages.CAMPO_MAIOR_ZERO(guaranteeValueLabel?.split(':').join('')), (value) => (value && Number(value) !== 0))
        .test('guaranteeValue', `O campo ${guaranteeValueLabel?.split(':').join('')} deverá ser maior que o valor da última Importância Segurada.`, (value) => (value && Number(value) > endorsement?.currentStateDocument?.guaranteeValue))
        .required()
    }
    if (!otherBrokerageDocument
      && (endorsementData?.id === ENDORSEMENT_TYPE_EXTENSION_REDUCTION_INSURED_AMOUNT
        || endorsementData?.id === ENDORSEMENT_TYPE_REDUCTION_INSURED_AMOUNT)) {
      return Yup.number()
        .test('guaranteeValue', GlobalMessages.CAMPO_MAIOR_ZERO(guaranteeValueLabel?.split(':').join('')), (value) => (value && Number(value) !== 0))
        .test('guaranteeValue', `O campo ${guaranteeValueLabel?.split(':').join('')} deverá ser menor que o valor da última Importância Segurada.`, (value) => (value && Number(value) < endorsement?.currentStateDocument?.guaranteeValue))
        .required()
    }
    if (otherBrokerageDocument && (
      endorsementData?.id === ENDORSEMENT_TYPE_EXTENSION_REDUCTION_INSURED_AMOUNT
        || endorsementData?.id === ENDORSEMENT_TYPE_REDUCTION_INSURED_AMOUNT
        || endorsementData?.id === ENDORSEMENT_TYPE_EXTENSION_INCREASE_INSURED_AMOUNT
        || endorsementData?.id === ENDORSEMENT_TYPE_INCREASE_INSURED_AMOUNT
    )) {
      return Yup.number().test('guaranteeValue', GlobalMessages.CAMPO_MAIOR_ZERO(`${INSURED_AMOUNT} `), (value) => (value && Number(value) !== 0))
        .required()
    }
    return Yup.number()
  }
  const maxStartDateValidityPolicy = useMemo(() => {
    const policyData = policyOrLastPolicyIsTypeExtension
    return !isEmpty(policyData)
      ? policyData
      : undefined
  }, [policyOrLastPolicyIsTypeExtension])

  const minStartDateValidityPolicy = useMemo(() => {
    const twoYearsRetroactive = date().subtract(2, 'years')

    return !isEmpty(twoYearsRetroactive)
      ? formatDateApi(twoYearsRetroactive)
      : undefined
  }, [
    formatDateApi,
    date,
  ])

  const minDateValidation = useCallback(() => {
    if (minStartDateValidityPolicy !== undefined) {
      return date(minStartDateValidityPolicy)
    }
    return undefined
  }, [date, minStartDateValidityPolicy])

  const minAndMaxStartDateValidity = () => {
    if (minStartDateValidityPolicy !== undefined) {
      return (Yup.date().when(() => (
        maxStartDateValidityPolicy && Yup.date()
          .max(maxStartDateValidityPolicy, `A data máxima permitida para o início de vigência é ${formatDate(date(maxStartDateValidityPolicy))}.`)
          .min(minStartDateValidityPolicy, `A data mínima permitida para o início de vigência é ${formatDate(date(minStartDateValidityPolicy))}.`)
          .required()
      )))
    }
    return (Yup.date().when(() => (
      maxStartDateValidityPolicy && Yup.date()
        .max(maxStartDateValidityPolicy, `A data máxima permitida para o início de vigência é ${formatDate(maxStartDateValidityPolicy)}.`)
        .required()
    )))
  }

  const isShowObjectIsRequired = () => (Yup.string().when(() => (
    !isShowObjectLabel()
      ? Yup.string().required()
      : Yup.string()
  )))

  const today = date()
  const dateLimit = date(today).add(1, 'days')

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: endorsement?.isEditing ? initialValuesFormEditing : initialValuesForm,
    validationSchema: Yup.object({
      name: Yup.string().required(),
      document: cpfOrCnpj.required(),
      deadline: deadline <= 0 ? Yup.number().test('deadline', GlobalMessages.CAMPO_MAIOR_ZERO('Prazo'), (value) => (value && Number(value) > 0)).required()
        : Yup.number().when((value) => (
          !value || !isShowDeadLineAndEndDateLabel()
            ? Yup.string().required()
            : Yup.string()
        )),
      startDateValidity: !otherBrokerageDocument
        ? minAndMaxStartDateValidity() : Yup.date()
          .min(minStartDateValidityPolicy, `A data mínima permitida para o início de vigência é ${formatDate(date(minStartDateValidityPolicy))}.`).required(),
      endDateValidity: Yup.date().min(today, `A data mínima permitida para o fim de vigência é ${formatDate(dateLimit)}`).required(),
      contractValue: fieldContractValue(),
      guaranteeValue: fieldGuaranteeValue(),
      objectText: Yup.string().when(() => (
        isShowObjectLabel()
          ? Yup.string().required()
          : Yup.string()
      )),
      coverageFine: Yup.string().when(() => (
        endorsementData?.modality === MODALITY_PRIVATE && !isShowObjectLabel()
          ? Yup.string().required()
          : Yup.string()
      )),
      coverageLabor: isShowObjectIsRequired(),
      civilWork: isShowObjectIsRequired(),
    }),
    onSubmit: (data) => {
      formik.values.name = formik.values.name?.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
      const values = {
        ...endorsement,
        ...{ newStateDocument: { ...data } },
      }
      setEndorsement(values)
      if (actionNext) {
        onNext(values)
      }
    },
  })

  useEffect(() => {
    const endDateValidity = formik.getFieldProps('endDateValidity')
    const startDateValidity = formik.getFieldProps('startDateValidity')

    setDeadline('')
    if (startDateValidity?.value && endDateValidity?.value) {
      if (endorsementData?.product === QUOTE_JUDICIAL_APPEAL) {
        const deadlineCount = date(endDateValidity?.value)
          .diff(date(startDateValidity?.value), 'years')
        if (deadlineCount < 3 || deadlineCount > 5) {
          setDeadline('')
          return
        }
        setDeadline(deadlineCount)

        return
      }
      const deadlineCount = date(endDateValidity?.value)
        .diff(date(startDateValidity?.value), 'days')
      setDeadline(deadlineCount)
    }
  }, [formik, date, setDeadline, endorsementData?.product])

  const handleContractValue = useCallback((event) => {
    const { value, valueConst } = event.target
    const valueDecimal = valueConst ?? formatCurrencyBRLToDecimal(value)
    setContractValue(valueDecimal)
  }, [
    formatCurrencyBRLToDecimal,
  ])

  const handleGuaranteeValue = useCallback((event) => {
    const { value, valueConst } = event.target
    const valueDecimal = valueConst ?? formatCurrencyBRLToDecimal(value)
    setGuaranteeValue(valueDecimal)
  }, [
    formatCurrencyBRLToDecimal,
  ])

  const handleInfoCnpj = (evento) => {
    const { value } = evento.target
    if (evento?.target?.value === '') {
      formik.setFieldValue('name', '')
    }

    const cnpj = value?.replace(/[^0-9]/g, '')

    if (isCnpj(cnpj)) {
      showLoader()
      receitaWSClient().getInfoByCnpj(cnpj).then(async (response) => {
        if (response) {
          formik.setFieldValue('name', response.nome)
          hideLoader()
        }
      }).catch(() => {
        formik.setFieldValue('name', '')
        hideLoader()
      })
    }
    if (cnpj && !isCnpj(cnpj)) {
      showLoader()
      userClient().getClientInsuredObjectProperty(cnpj).then(async (response) => {
        if (response) {
          formik.setFieldValue('name', response?.data?.nome)
          hideLoader()
        }
      }).catch(() => {
        formik.setFieldValue('name', '')
        hideLoader()
      })
    }
  }

  const handleChangeDeadline = () => {
    const objectDeadline = formik.getFieldProps('deadline')
    const startDateValidity = formik.getFieldProps('startDateValidity')

    setEndDate('')
    if (!isEmpty(objectDeadline?.value) && !isEmpty(startDateValidity?.value)) {
      const endDateUpdated = date(startDateValidity?.value)
        .add(objectDeadline?.value, 'days')

      setEndDate(formatDateApi(endDateUpdated))
    }
  }

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  const placeholderCPFCNPJ = '000.000.000-00 / 00.000.000/0000-00'

  const nameLabel = 'Beneficiário'
  const endDateValidityLabel = 'Fim de Vigência'
  const cpfCnpjLabel = 'CPF/CNPJ do Beneficiário'
  const objectLabel = 'Obrigação Garantia (Objeto)'
  const startDateValidityLabel = 'Início de Vigência'
  const civilWorkLabel = 'Obra Civil:'
  const fineLabel = 'O contrato exige cobertura para multa:'
  const alertMsg = '(*) Esta cobertura poderá onerar o custo da garantia'
  const laborLabel = 'O contrato exige cobertura para cláusula trabalhista:'
  const alertMsgMandatoryFine = '(*) A cobertura para multa é obrigatória para performance pública'

  const inputDeadline = () => {
    if (verifyJudicials) {
      return (
        <>
          <DropdownSelectOne
            id="deadline"
            options={DEADLINE_YEARS}
            placeholder="Selecione"
            {...formik.getFieldProps('deadline')}
            error={formik.touched.deadline && !!formik.errors.deadline}
            onChange={(e) => {
              const { value } = e.target
              formik.setFieldValue('deadline', value)

              const newEndDate = date(formik.values.startDateValidity).add(value, 'years')
              formik.setFieldValue('endDateValidity', formatDateApi(newEndDate))
            }}
            disabled={otherBrokerageDocument ? false : isShowDeadLineAndEndDateLabel()}
          />
        </>
      )
    }
    return (
      <Input
        fullWidth
        id="deadline"
        color="secondary"
        {...formik.getFieldProps('deadline')}
        error={formik.touched.deadline && !!formik.errors.deadline}
        onKeyUp={(event) => {
          const { value } = event.target
          if (isBlank(value)) {
            formik.setFieldValue('deadline', '')
          }
          formik.setFieldValue('deadline', value)
          handleChangeDeadline()
        }}
        onBlur={(event) => {
          const { value } = event.target
          if (!isBlank(value)) {
            formik.setFieldValue('endDateValidity', formatDateApi(endDate))
          }
        }}
        disabled={otherBrokerageDocument ? false : isShowDeadLineAndEndDateLabel()}
      />
    )
  }

  return (
    <>
      <CollapsePrice
        checked={checked}
        handleEdit={handleStep}
        hiddenEdit={!formik.isSubmitting}
        title="Dados do Novo Endosso"
        subtitle="Informe abaixo os dados do novo endosso"
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item sm={4} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="cpfCnpjLabel" className={classes.label}>{cpfCnpjLabel}</label>
                  <CPFOrCNPJInput
                    fullWidth
                    id="document"
                    color="secondary"
                    placeholder={placeholderCPFCNPJ}
                    {...formik.getFieldProps('document')}
                    error={formik.touched.document && !!formik.errors.document}
                    onBlur={handleInfoCnpj}
                    onChange={(e) => {
                      formik.setFieldValue('document', e.target.value)
                      formik.setFieldValue('name', '')
                    }}
                  />
                  <FormHelperText
                    hidden={!formik.touched.document || !formik.errors.document}
                    error={formik.touched.document && !!formik.errors.document}
                  >
                    {formik.errors.document}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={8} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="nameLabel" className={classes.label}>{nameLabel}</label>
                  <Input
                    fullWidth
                    id="name"
                    color="secondary"
                    placeholder="Nome do Beneficiário"
                    disabled={otherBrokerageDocument ? false : !formik.values.document}
                    {...formik.getFieldProps('name')}
                    error={formik.touched.name && !!formik.errors.name}
                    onChange={(e) => {
                      const { value } = e.target
                      formik.setFieldValue('name', value
                        ?.normalize('NFD')?.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''))
                    }}
                  />
                  <FormHelperText
                    hidden={!formik.touched.name || !formik.errors.name}
                    error={formik.touched.name && !!formik.errors.name}
                  >
                    {formik.errors.name}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item sm={4} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="startDateValidityLabel" className={classes.label}>
                    {startDateValidityLabel}
                  </label>
                  <DateInput
                    disabled={otherBrokerageDocument ? false : isShowStartDateLabel()}
                    id="startDateValidity"
                    title={startDateValidityLabel}
                    minDate={minDateValidation()}
                    maxDate={!otherBrokerageDocument ? maxStartDateValidityPolicy : undefined}
                    {...formik.getFieldProps('startDateValidity')}
                    error={formik.touched.startDateValidity
                      && !!formik.errors.startDateValidity}
                    helperText={formik.touched.startDateValidity
                      && formik.errors.startDateValidity}
                    onChange={(event) => {
                      const { value } = event.target
                      if (isBlank(value)) {
                        formik.setFieldValue('startDateValidity', '')
                      }

                      formik.setFieldValue('startDateValidity', formatDateApi(value))

                      formik.setFieldValue('deadline', deadline)
                    }}
                    onBlur={() => formik.setFieldValue('deadline', deadline)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="endDateValidityLabel" className={classes.label}>
                    {endDateValidityLabel}
                  </label>
                  <DateInput
                    disabled={otherBrokerageDocument ? false : isShowDeadLineAndEndDateLabel()}
                    minDate={dateLimit}
                    id="endDateValidity"
                    title={endDateValidityLabel}
                    {...formik.getFieldProps('endDateValidity')}
                    error={formik.touched.endDateValidity
                      && !!formik.errors.endDateValidity}
                    helperText={formik.touched.endDateValidity
                      && formik.errors.endDateValidity}
                    onChange={(event) => {
                      const { value } = event.target
                      if (isBlank(value)) {
                        formik.setFieldValue('endDateValidity', '')
                      }
                      formik.setFieldValue('endDateValidity', formatDateApi(value))

                      formik.setFieldValue('deadline', deadline)

                      setEndDate(formatDateApi(value))
                    }}
                    onBlur={() => formik.setFieldValue('deadline', deadline)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="deadlineLabel" className={classes.label}>{deadlineLabel}</label>
                  {inputDeadline()}
                  <FormHelperText
                    hidden={!formik.touched.deadline || !formik.errors.deadline}
                    error={formik.touched.deadline && !!formik.errors.deadline}
                  >
                    {formik.errors.deadline}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="contractValueLabel" className={classes.label}>{contractValueLabel}</label>
                  <CurrencyInput
                    fullWidth
                    id="contractValue"
                    color="secondary"
                    value={contractValue}
                    {...formik.getFieldProps('contractValue')}
                    error={
                      formik.touched.contractValue && !!formik.errors.contractValue
                    }
                    onKeyPress={handleContractValue}
                    onBlur={handleContractValue}
                    disabled={!otherBrokerageDocument ? isShowContractAndGuaranteeLabel() : false}
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.contractValue || !formik.errors.contractValue
                    }
                    error={
                      formik.touched.contractValue && !!formik.errors.contractValue
                    }
                  >
                    {formik.errors.contractValue}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {(!otherBrokerageDocument ? !isTypeNoInsuredAmount() : true) && (
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <label htmlFor="guaranteeValueLabel" className={classes.label}>{!otherBrokerageDocument ? guaranteeValueLabel : 'Importância Segurada'}</label>
                    <CurrencyInput
                      fullWidth
                      id="guaranteeValue"
                      color="secondary"
                      value={guaranteeValue}
                      {...formik.getFieldProps('guaranteeValue')}
                      error={
                        formik.touched.guaranteeValue && !!formik.errors.guaranteeValue
                      }
                      onKeyPress={handleGuaranteeValue}
                      onBlur={handleGuaranteeValue}
                      disabled={!otherBrokerageDocument ? isShowContractAndGuaranteeLabel() : false}
                    />
                    <FormHelperText
                      hidden={
                        !formik.touched.guaranteeValue || !formik.errors.guaranteeValue
                      }
                      error={
                        formik.touched.guaranteeValue && !!formik.errors.guaranteeValue
                      }
                    >
                      {formik.errors.guaranteeValue}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item sm={12} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="objectLabel" className={classes.labelObject}>{objectLabel}</label>
              <TextField
                fullWidth
                id="objectText"
                multiline
                variant="outlined"
                minRows={3}
                {...formik.getFieldProps('objectText')}
                error={formik.touched.objectText && !!formik.errors.objectText}
                disabled={otherBrokerageDocument ? false : !isShowObjectLabel()}
                onChange={(e) => {
                  const { value } = e.target
                  formik.setFieldValue('objectText', value?.replace('', '')?.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, ''))
                }}
              />
              <FormHelperText
                hidden={!formik.touched.objectText || !formik.errors.objectText}
                error={formik.touched.objectText && !!formik.errors.objectText}
              >
                {formik.errors.objectText}
              </FormHelperText>
            </FormControl>
            {!(endorsementData?.product === QUOTE_PERFORMANCE && !isShowObjectLabel())
              && endorsementData?.id === ENDORSEMENT_TYPE_INCLUSION_COVERAGE
              && (
                <Typography style={{ marginTop: 35 }}>
                  Nenhuma cobertura adicional disponível para este endosso!
                </Typography>
              )}
          </Grid>
          {
            (endorsementData?.product === QUOTE_PERFORMANCE && !isShowObjectLabel()) && (
              <>
                {endorsementData?.modality === MODALITY_PRIVATE && (
                  <Grid
                    item
                    sm={12}
                    xs={12}
                  >
                    <FormControl fullWidth>
                      <label htmlFor="FineLabel" className={classes.label}>{fineLabel}</label>
                      <RadioGroup
                        row
                        id="coverageFine"
                        name="coverageFine"
                        aria-label="gender"
                        {...formik.getFieldProps('coverageFine')}
                        touched={formik.touched.coverageFine?.toString()}
                      >
                        <FormControlLabel value="true" control={<Radio color="primary" />} label="Sim" />
                        <FormControlLabel value="false" control={<Radio color="primary" />} label="Não" />
                        <Grid className={classes.text}>
                          <Typography hidden={formik.getFieldProps('coverageFine').value === '' || formik.getFieldProps('coverageFine').value === 'false'}>{alertMsg}</Typography>
                        </Grid>
                      </RadioGroup>
                      <FormHelperText
                        hidden={!formik.touched.coverageFine || !formik.errors.coverageFine}
                        error={formik.touched.coverageFine && !!formik.errors.coverageFine}
                      >
                        {formik.errors.coverageFine}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                )}
                {endorsementData?.modality === MODALITY_PUBLIC && (
                  <Grid
                    item
                    sm={12}
                    xs={12}
                  >
                    <FormControl fullWidth>
                      <label htmlFor="FineLabel" className={classes.label}>{fineLabel}</label>
                      <RadioGroup
                        row
                        id="coverageFine"
                        name="coverageFine"
                        aria-label="gender"
                        defaultValue="true"
                        touched={formik.touched.coverageFine?.toString()}
                      >
                        <FormControlLabel value="true" control={<Radio color="primary" />} label="Sim" />
                        <FormControlLabel value="false" control={<Radio disabled color="primary" />} label="Não" />
                        <Grid className={classes.text}>
                          <Typography>{alertMsgMandatoryFine}</Typography>
                        </Grid>
                      </RadioGroup>
                      <FormHelperText
                        hidden={!formik.touched.coverageFine || !formik.errors.coverageFine}
                        error={formik.touched.coverageFine && !!formik.errors.coverageFine}
                      >
                        {formik.errors.coverageFine}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                )}
                <Grid
                  item
                  sm={12}
                  xs={12}
                >
                  <FormControl fullWidth>
                    <label htmlFor="FineLabel" className={classes.label}>{laborLabel}</label>
                    <RadioGroup
                      row
                      id="coverageLabor"
                      aria-label="gender"
                      {...formik.getFieldProps('coverageLabor')}
                      touched={formik.touched.coverageLabor?.toString()}
                    >
                      <FormControlLabel value="true" control={<Radio color="primary" />} label="Sim" />
                      <FormControlLabel value="false" control={<Radio color="primary" />} label="Não" />
                      <Grid className={classes.text}>
                        <Typography hidden={formik.getFieldProps('coverageLabor').value === '' || formik.getFieldProps('coverageLabor').value === 'false'}>{alertMsg}</Typography>
                      </Grid>
                    </RadioGroup>
                    <FormHelperText
                      hidden={!formik.touched.coverageLabor || !formik.errors.coverageLabor}
                      error={formik.touched.coverageLabor && !!formik.errors.coverageLabor}
                    >
                      {formik.errors.coverageLabor}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                >
                  <FormControl fullWidth>
                    <label htmlFor="FineLabel" className={classes.label}>{civilWorkLabel}</label>
                    <RadioGroup
                      row
                      id="civilWork"
                      aria-label="gender"
                      {...formik.getFieldProps('civilWork')}
                      touched={formik.touched.civilWork?.toString()}
                    >
                      <FormControlLabel value="true" control={<Radio color="primary" />} label="Sim" />
                      <FormControlLabel value="false" control={<Radio color="primary" />} label="Não" />
                    </RadioGroup>
                    <FormHelperText
                      hidden={!formik.touched.civilWork || !formik.errors.civilWork}
                      error={formik.touched.civilWork && !!formik.errors.civilWork}
                    >
                      {formik.errors.civilWork}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </>
            )
          }

          <Grid item xs={12}>
            <Box className={classes.buttonsStep}>
              <Box>
                <ButtonLight
                  onClick={handlePrevious}
                  title="Voltar"
                >
                  Voltar
                </ButtonLight>
              </Box>
              <Box ml={4}>
                <ButtonGradient
                  onClick={handleNext}
                  title="Continuar"
                >
                  Continuar
                </ButtonGradient>
              </Box>
            </Box>
            <ReactTooltip place="top" type="dark" />
          </Grid>
        </Grid>
      </CollapsePrice>
    </>
  )
})

NewStateDocument.propTypes = {
  checked: PropTypes.bool,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

NewStateDocument.defaultProps = {
  checked: false,
}

export default NewStateDocument
